<template>
  <component is="b-card">
    <!-- Tab: Received -->
    <b-tab active @click="receivedClicked">
      <order-received-tab
        ref="receivedRef"
        class="mt-2 pt-75"
        :sub-profiles="subProfiles"
        :emergency-degrees="emergencyDegrees"
        :clients="clients"
      />
    </b-tab>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import OrderReceivedTab from "./OrderReceivedTab.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import instance from "@/libs/axios";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,

    OrderReceivedTab,
  },
  data() {
    return {
      subProfiles: [],
      emergencyDegrees: [],
      clients: [],
    };
  },
  methods: {
    receivedClicked() {
      this.$refs["receivedRef"].refreshTable();
    },
  },
  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/subprofiles/"),
        instance.get("/parameters/emergency-degrees/"),
        instance.get("/parameters/clients/"),
      ]);
      this.subProfiles = res[0].data;
      this.emergencyDegrees = res[1].data;
      this.clients = res[2].data.results;
    } catch (err) {
      const error = err.response
        ? Object.values(err.response.data)[0][0]
        : err.message;

      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: error ?? this.$t("An error has occurred. Please try again"),
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>

<style></style>
